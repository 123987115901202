import React from 'react';
import Layout from '../components/Layout';
import Pagination from '@sentry/static-shared/components/Pagination';
import SEO from '../components/SEO';
import Layer from '@sentry/static-shared/components/layer-next';
import { white, bgLight, richBlack } from '@sentry/static-shared/utils/css/colors';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { mqMin } from '@sentry/static-shared/utils/css';
import { randomFrom } from '@sentry/static-shared/utils/utils';
import LinkList from '@sentry/static-shared/components/LinkList';
import excerptFromNode from '@sentry/static-shared/utils/excerpt-from-node';
import TimelineCard from '@sentry/static-shared/components/TimelineCard';

const ArchivePage = ({
  data: {
    allContentfulBlogPost,
    allContentfulPerson,
    contentfulPage: blogData,
    site: {
      siteMetadata: { siteUrl },
    },
  },
  pageContext,
  location,
}) => {
  const defaultAvatars = blogData.dataModules.find(
    x => x.slug === 'fallback-avatars'
  ).assets;

  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base',
  });

  const authorLinks = allContentfulPerson.edges
    .map((x, i) => ({ index: i, ...x.node }))
    // Reduce the list to people that qualify as authors
    .filter(node => {
      const include =
        // Author must have at least one blog post
        !!node.blog_post &&
        node.blog_post.length > 0 &&
        // Author must have a name
        !!node.name;
      return include;
    })
    .sort((a, b) => {
      // Sort DESC by number of posts written
      if (a.blog_post.length !== b.blog_post.length) {
        return a.blog_post.length > b.blog_post.length ? -1 : 1;
      }

      // Sort ties alphabetically
      return collator.compare(a.name, b.name);
    })
    .map(node => {
      const url = node.fields ? node.fields.url : '/404/';
      const avatar = node.avatar || randomFrom(defaultAvatars);
      return [
        `${node.name} (${node.blog_post.length})`,
        url,
        {
          portrait: avatar.file.url,
        },
      ];
    });

  const image = allContentfulBlogPost.edges[0].node.metaImage;

  return (
    <Layout>
      <StyledMain tag="main" backgroundColor={richBlack} lightType>
        <SEO
          title="Post Archives"
          image={image}
          canonical={`${siteUrl}${location.pathname}`}
        />
        <StyledRow>
          <StyledPostList>
            {allContentfulBlogPost.edges.map(({ node }) => (
              <TimelineCard
                mode="dark"
                key={node.id}
                to={node.fields.url}
                date={node.publishDate}
                title={node.title || 'Untitled Post'}
                body={excerptFromNode(node)}
              />
            ))}
          </StyledPostList>
          <StyledSideBar>
            <LinkList heading="Authors" links={authorLinks} />
          </StyledSideBar>
        </StyledRow>
        <Pagination pageContext={pageContext} />
      </StyledMain>
    </Layout>
  );
};

const StyledRow = styled.div`
  ${mqMin('md')} {
    display: flex;
  }
`;

const StyledPostList = styled.div`
  margin-bottom: 1.5rem;

  ${mqMin('md')} {
    margin-bottom: 0;
    flex-shrink: 1;
    max-width: 75%;
  }
`;

const StyledSideBar = styled.div`
  li {
    color: ${white};
  }
`;

const StyledMain = styled(Layer)`
  flex: 1;

  > div {
    padding-top: 0.5rem;
  }
`;

export default ArchivePage;

export const blogListQuery = graphql`
  query blogArchiveQuery($posts: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    # Global Blog Data
    contentfulPage(contentful_id: { eq: "6PaNQpeuz5DLUwC3KP09R5" }) {
      dataModules {
        slug
        assets {
          ...Image
        }
      }
    }

    allContentfulBlogPost(
      filter: { slug: { in: $posts } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
    allContentfulPerson {
      edges {
        node {
          ...Author
        }
      }
    }
  }
`;
